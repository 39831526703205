<template>
  <div id="award-preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <div class="d-flex justify-content-center mt-2 mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-container>
              <h6 class="text-center purple--text">Award Details</h6>
              <v-row wrap>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <h6>Award Staus</h6>
                  <h6 class="text-dark">
                    {{ row.AwardStatusTxt }}
                    <!-- <v-chip :color="row.AwardStatusColor" draggable>
                      {{ row.AwardStatusTxt }}
                    </v-chip> -->
                  </h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <h6>Award Register No</h6>
                  <h6 class="text-dark">{{ row.AwardRegisterNumber }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <h6>Award Type</h6>
                  <h6 class="text-dark">{{ row.AwardTypeTitleTxt }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <h6>LO Name</h6>
                  <h6 class="text-dark">{{ row.LomName }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <h6>Zone Name</h6>
                  <h6 class="text-dark">{{ row.ZoneDisplayText }}</h6>
                </v-col>
              </v-row>
              <hr />
              <h6 class="text-center purple--text mt-5">Nominee Details</h6>
              <v-row wrap>
                <v-col cols="12" sm="12" md="12" lg="12" class="text-center">
                  <img
                    :src="row.MemberImage"
                    :alt="row.MemberFullName"
                    style="width: 100px"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <h6>Membership Id</h6>
                  <h6 class="text-dark">{{ row.MembershipId }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <h6>Member Name</h6>
                  <h6 class="text-dark">{{ row.MemberFullName }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <h6>Email Id</h6>
                  <h6 class="text-dark">{{ row.EmailId }}</h6>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <h6>Mobile No</h6>
                  <h6 class="text-dark">{{ row.MobileNo }}</h6>
                </v-col>
              </v-row>
              <hr />
              <h6 class="text-center purple--text mt-5">
                Judging Criteria Details
              </h6>
              <v-row wrap v-for="item in row.AwardAnswersArray" :key="item.SNo">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <template v-if="item.CriteriaTitleFlag">
                    <h6 class="text-dark">
                      <b>{{ item.CriteriaTitle }}</b>
                    </h6>
                  </template>
                  <h6 class="blue--text mt-0">
                    {{ item.QuestionsText }}
                  </h6>
                  <h6 class="text-dark">Answer :- {{ item.AnswerText }}</h6>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  v-if="item.EventDetails.length > 0"
                >
                  <h6 class="mt-4 mb-2 purple--text">Attachments</h6>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      lg="3"
                      v-for="(row, index) in item.EventDetails"
                      :key="index"
                    >
                      <v-card>
                        <v-card-text>
                          <h6 class="text-center">
                            <img
                              :src="row.EventImages"
                              :alt="row.EventName"
                              style="width: 80px; height: 80px"
                            />
                          </h6>
                          <h6>
                            Event Date :
                            <span class="text-dark">
                              {{ row.EventDate }}
                            </span>
                          </h6>
                          <h6>
                            Event Name :
                            <span class="text-dark">
                              {{ row.EventName }}
                            </span>
                          </h6>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          medium
          color="#a52a2a"
          class="font-size-h6 px-6 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  props: {
    previewRecordPrompt: {
      required: true,
      Type: Boolean,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      LoadingFlag: false,
      SubmitFlag: false,
      OverlayLoadingFlag: false,
      search: "",

      ProgessStart: 0,
      uploadPercentage: 0,

      valid: true,

      row: {},
      AwardId: 0,

      RoleId: 0,
      PageInfo: {},
      PageTitle: "Award Preview",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      console.log("recordData = " + JSON.stringify(this.recordData));
      this.AwardId = this.recordData.AwardId;

      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var AwardId = this.AwardId;
      console.log({ AwardId });

      if (AwardId > 0) {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/show";
        var upload = {
          UserInterface: 2,
          Award: this.AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log({ server_url }, { token }, { add_url });

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log({ output }, { flag });

            console.log({ records });

            thisIns.row = records;
          })
          .catch(function (error) {
            console.log({ error });
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#award-preview {
  .v-speed-dial--bottom {
    bottom: 12%;
  }
}
</style>
